var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c("v-subheader", [_vm._v("Change Your Password")]),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "", md6: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: { xs12: "", sm12: "", md6: "", "pa-3": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [_vm.rules.required],
                                  clearable: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("RequiredDot", [
                                          _vm._v("Old Password")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.old_password,
                                  callback: function($$v) {
                                    _vm.old_password = $$v
                                  },
                                  expression: "old_password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: { xs12: "", sm12: "", md6: "", "pa-3": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "password",
                                  "persistent-hint": "",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.moreCounter
                                  ],
                                  hint:
                                    "The password must contain at least 8 characters or numbers.",
                                  clearable: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("RequiredDot", [
                                          _vm._v("New Password")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.new_password,
                                  callback: function($$v) {
                                    _vm.new_password = $$v
                                  },
                                  expression: "new_password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: { xs12: "", sm12: "", md6: "", "pa-3": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "password",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.moreCounter,
                                    function(value) {
                                      return (
                                        !value ||
                                        value === _vm.new_password ||
                                        "Passwords do not match."
                                      )
                                    }
                                  ],
                                  clearable: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("RequiredDot", [
                                          _vm._v("New Password Confirmation")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "mt-2",
          staticStyle: { height: "60px" },
          attrs: {
            "align-content-end": "",
            "justify-center": "",
            "align-center": ""
          }
        },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "text-none",
              attrs: { loading: _vm.loading, color: "primary" },
              on: { click: _vm.changePassword }
            },
            [_vm._v("Update")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }