<template>
    <div>
        <v-card tile>
            <v-subheader>Change Your Password</v-subheader>
            <v-divider></v-divider>
            <v-form ref="form">
                <v-layout>
                    <v-flex xs12 sm6 md6>
                        <v-layout column>
                            <v-flex xs12 sm12 md6 pa-3>
                                <v-text-field v-model="old_password"
                                              :rules="[rules.required]"
                                              clearable>
                                    <template v-slot:label>
                                        <RequiredDot>Old Password</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md6 pa-3>
                                <v-text-field v-model="new_password"
                                              type="password"
                                              persistent-hint
                                              :rules="[rules.required,rules.moreCounter]"
                                              hint="The password must contain at least 8 characters or numbers."
                                              clearable>
                                    <template v-slot:label>
                                        <RequiredDot>New Password</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md6 pa-3>
                                <v-text-field
                                        type="password"
                                        :rules="[rules.required,rules.moreCounter,value => !value||value===new_password||'Passwords do not match.']"
                                        clearable>
                                    <template v-slot:label>
                                        <RequiredDot>New Password Confirmation</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-card>
        <v-layout class="mt-2" style="height: 60px;" align-content-end justify-center align-center>
            <v-spacer></v-spacer>
            <v-btn class="text-none" :loading="loading" color="primary" @click="changePassword">Update</v-btn>
        </v-layout>
    </div>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'

    export default {
        name: "Password",
        components: {
            RequiredDot,
        },
        data() {
            return {
                loading: false,
                rules: {
                    ...rules,
                },
                old_password: '',
                new_password: '',
            }
        },
        methods: {
            changePassword() {
                if (!this.$refs.form.validate()) {
                    return
                }
                this.loading = true
                this.$http({
                    method: 'put',
                    url: `/clients/${this.$store.state.client.clientInfo.id}/change-password/`,
                    data: {
                        old_password: this.old_password,
                        new_password: this.new_password
                    }
                }).then(() => {
                    this.$message.success('Your password was reset successfully !')
                    this.loading = false
                }).catch((err) => {
                    this.$message.error(err.detail)
                    this.loading = false
                })
            }
        },
        created() {
            this.$SDK.track({
                pageName : 'Password',
            })
        }
    }
</script>

<style lang="scss">
</style>
